<template>
  <div :is-label-left="isLabelLeft" :class="`${fullContainerClass}`">
    <label
      :id="name"
      :for="`${type}-input`"
      :is-label-left="isLabelLeft"
      class="capitalize text-grayscale-70-2"
      :class="labelClass"
    >
      {{ label }}<span v-if="isMandatory" class="text-accent-magenta"> *</span>
    </label>
    <div
      :is-label-left="isLabelLeft"
      :class="`relative flex flex-col ${inputContainerClass}`"
    >
      <div :class="`relative w-full flex ${inputContainerClass}`">
        <label v-if="isCurrency" id="currency" class="absolute">$</label>
        <textarea
          v-if="type === 'textarea'"
          class="w-full py-3 px-2 text-white box-border border rounded-4 placeholder-grayscale-40 resize-none h-36 hover:bg-grayscale-2 focus:bg-grayscale-2 focus:border-grayscale-40 focus:outline-none focus:ring-0"
          v-model="inputValue"
          :maxlength="maxLength"
          :class="`${
            !stylesClear && 'py-3 px-2 box-border border-2 rounded-4'
          } ${
            errorText && 'border-helpers-validation'
          } ${bgColor} ${borderColor} ${paddingRight} ${disabled} ${inputClass}`"
          :id="`${name}-input`"
          :disabled="isDisabled || isLoading"
          :name="`${name}-input`"
          :type="inputType"
          :autocomplete="isAutocomplete"
          :placeholder="`${placeholder}`"
          @focus="disableError"
          @keydown.enter="proceed($event)"
        />
        <div
          v-if="type === 'textarea' && maxLength && !hideMaxLength"
          class="text-xs text-grayscale-50 mr-3 mb-1 flex-shrink-0 absolute bottom-0 right-0"
        >
          {{ inputLength ?? "0" }}
          / {{ maxLength }}
        </div>

        <div
          v-if="type === 'search'"
          class="flex items-center w-full px-5 text-white placeholder-grayscale-40 focus:outline-none focus:ring-0 group"
        >
          <i class="icon-search text-20 leading-5 text-grayscale-96 mr-2.5" />
          <input
            ref="inputField"
            v-model="inputValue"
            :class="`px-2 border-none outline-none focus:border-none focus:ring-0 bg-transparent w-full text-sm
         ${disabled} ${inputClass}`"
            :id="`${name}-input`"
            :disabled="isDisabled || isLoading"
            :name="`${name}-input`"
            type="text"
            :placeholder="`${placeholder}`"
            :autocomplete="isAutocomplete"
          />
          <button
            v-if="inputValue"
            type="reset"
            class="absolute border-none block w-6 h-6 right-2 top-0 bottom-0 p-0 m-auto rounded-full border-2 border-grayscale-50 bg-grayscale-13 cursor-pointer outline-none text-grayscale-50"
            @click="clearContent"
          >
            &times;
          </button>
        </div>

        <input
          ref="inputField"
          v-if="!['textarea', 'search'].includes(type)"
          class="w-full px-5 text-white box-border border rounded-4 placeholder-grayscale-40 hover:bg-grayscale-2 focus:bg-grayscale-2 focus:border-grayscale-40 focus:outline-none focus:ring-0"
          v-model="inputValue"
          :class="`${
            !stylesClear &&
            'px-2 box-border border-2 focus:border-grayscale-40 rounded-4 hover:bg-grayscale-2 focus:bg-grayscale-2'
          } ${
            errorText && 'border-helpers-validation'
          } ${bgColor} ${borderColor} ${paddingRight} ${disabled} ${inputClass}
          ${isCurrency ? 'pl-8' : ''}`"
          :id="`${name}-input`"
          :disabled="isDisabled || isLoading"
          :name="`${name}-input`"
          :type="showPassword ? 'text' : inputType"
          :autocomplete="isAutocomplete"
          :placeholder="`${placeholder}`"
          @focus="disableError"
          @keydown.enter="proceed($event)"
          @keydown="checkCurrency($event)"
          @input="checkPattern($event)"
          @blur="blur()"
          min="0"
          :pattern="pattern"
        />
      </div>
      <button
        v-if="hasClearButton && inputValue"
        id="reset-password"
        type="reset"
        class="absolute border-none block w-6 h-6 right-2 top-0 bottom-0 p-0 m-auto rounded-full border-2 border-grayscale-50 bg-grayscale-13 cursor-pointer outline-none text-grayscale-50"
        @click="clearContent"
      >
        &times;
      </button>

      <button
        id="view-password"
        type="button"
        v-if="isPasswordType"
        class="absolute right-3 top-0 bottom-0 p-0 m-auto outline-none text-grayscale-50 flex justify-center items-center"
        @click="showPassword = !showPassword"
      >
        <img :src="!showPassword ? eye : eyeSlash" alt="eye" class="h-19px" />
      </button>
      <div v-if="isLoading" class="loader"></div>
    </div>

    <div
      v-if="errorText"
      class="bg-helpers-validation flex items-center mx-2 py-1 px-3 rounded-b font-normal text-15 leading-140 text-grayscale-96"
    >
      {{ errorText }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import eye from "@/assets/icons/eye.svg";
import eyeSlash from "@/assets/icons/eye-slashed.svg";

const props = defineProps({
  name: {
    type: String,
    default: "",
  },
  label: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  value: {
    type: [String, Number],
  },
  errorMessage: {
    type: String,
    default: "",
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  isAutocomplete: {
    type: String,
    default: "on",
  },
  labelClass: {
    type: String,
    default: "",
  },
  fullContainerClass: {
    type: String,
    default: "",
  },
  inputContainerClass: {
    type: String,
    default: "",
  },
  inputClass: {
    type: String,
    default: "",
  },
  isLabelLeft: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  stylesClear: {
    type: Boolean,
    default: false,
  },
  hasClearButton: {
    type: Boolean,
    default: false,
  },
  isMandatory: {
    type: Boolean,
    default: false,
  },
  isCurrency: {
    type: Boolean,
    default: false,
  },
  pattern: {
    type: String,
    default: "",
  },
  bgColor: {
    type: String,
    default: "bg-grayscale-7",
  },
  maxLength: {
    type: Number,
  },
  focus: {
    type: Boolean,
    default: false,
  },
  hideMaxLength: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits([
  "proceed",
  "clearError",
  "update:value",
  "update:errorMessage",
  "blur",
]);
const inputValue = ref(props.value);
const inputType = ref(props.type);
const borderColor = ref("border-grayscale-13");
const paddingRight = ref("");
const showPassword = ref(false);

const inputField = ref();

onMounted(() => {
  if (props.focus) {
    inputField.value.focus();
  }
});

const disabled = computed(() =>
  props.isDisabled || props.isLoading ? "bg-gray-100 text-grayscale-30" : "",
);

const errorText = computed({
  get: () => props.errorMessage,
  set: (value) => emit("update:errorMessage", value),
});
const isPasswordType = computed(() => props.type === "password");
const inputLength = computed(() => inputValue.value?.toString().length);

watch(
  () => inputLength.value,
  () => {
    if (
      props.maxLength &&
      inputValue.value &&
      inputValue.value.toString().length === props.maxLength
    ) {
      errorText.value = `The character limit is ${props.maxLength}.`;
    }
  },
);

function checkCurrency(event: Event) {
  if (props.isCurrency) {
    if ([".", ",", "+", "-"].includes(event.key)) {
      event.preventDefault();
    }
  }
}

function checkPattern(event: Event): void {
  if (props.pattern) {
    const input = event.target as HTMLInputElement;
    const pattern = new RegExp(props.pattern);
    if (!pattern.test(input.value)) {
      input.value = input.value.slice(0, -1);
    }
  }
}

function proceed(event: Event): void {
  if (event && event.target instanceof HTMLInputElement) {
    event.target.blur();
  }
  emit("proceed");
}

function clearContent(): void {
  inputValue.value = "";
}

function disableError(): void {
  errorText.value = "";
  emit("clearError", props.name);
}
function blur(): void {
  emit("blur");
}

watch(
  () => props.value,
  () => {
    inputValue.value = props.value;
  },
);

watch(inputValue, () => {
  paddingRight.value = isPasswordType.value ? "pr-16" : "pr-10";
  emit("update:value", inputValue.value);
});

watch(
  () => props.errorMessage,
  () => {
    errorText.value = props.errorMessage;
    borderColor.value = props.errorMessage
      ? "border-red-500"
      : "border-grayscale-13";
    paddingRight.value = "pr-10";
  },
);
</script>

<style scoped>
#currency {
  top: 50%;
  left: 20px;
  transform: translate(0, -50%);
  color: #45454d;
}
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
